import React, { useEffect } from "react"
import { useAuth0 } from "@auth0/auth0-react"
import { Renew32 } from "@carbon/icons-react"

import useAuth0RedirectOptions from "../../../../../themes/gatsby-theme-rothenberger/src/hooks/useAuth0RedirectOptions"

const LoginRedirectPage = () => {
  const { redirectOptions } = useAuth0RedirectOptions()
  const { loginWithRedirect } = useAuth0()

  useEffect(() => {
    if (redirectOptions) {
      loginWithRedirect(redirectOptions)
    }
  }, [loginWithRedirect, redirectOptions])

  return (
    <div className="py-20 flex justify-center align-middle">
      <Renew32 className="animate-spin transform rotate-180 inline-block -mt-0.5 align-middle" />
    </div>
  )
}

export default LoginRedirectPage
