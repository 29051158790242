/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react"

const useAuth0RedirectOptions = () => {
  const [redirectOptions, setRedirectOptions] = useState(null)

  useEffect(() => {
    if (window && window.location && window.location.search) {
      const language = window.location.pathname.split("/")[1].split("-")[1]
      const queryString = window.location.search
      const params = new URLSearchParams(queryString)
      setRedirectOptions({
        appState: {
          targetUrl: params.get("redirect"),
        },
        language: language,
      })
    }
  }, [])
  return {
    redirectOptions,
  }
}

export default useAuth0RedirectOptions
